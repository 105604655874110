.upgrade-plan-wrapper {
  .pricing-header {
    min-height: 400px;
    background-color: var(--mask-bg-color);
    width: 100%;
    .pricing-title {
      font-size: 3rem;
      font-weight: 600;
      position: relative;
      text-align: center;
      padding-top: 2.5rem;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        height: 8px;
        width: 60px;
        bottom: -10px;
        border-radius: 4px;
        background-color: #f5854a;
      }
    }
    .credit-desc {
      text-align: center;
    }
    .plan-controler {
      width: 100%;
      justify-content: center;
      margin-bottom: 20px;
      .ant-switch-checked {
        background-color: #000000;
        &:focus {
          box-shadow: 0 0 0 2px #0000001a;
        }
      }
      .monthly-plan,
      .yearly-plan {
        color: var(--primary-font-color);
        margin: 0;
      }
    }
  }
  .upgrade-plan-container {
    display: flex;
    justify-content: center;
    padding-bottom: 5rem;
    margin-top: -110px;
    gap: 30px;
    .pricing-card {
      position: relative;
      min-height: 550px;
      box-shadow: var(--box-shadow);
      border-radius: 4px;
      transition: all 0.4s ease-in-out;
      .ant-card-body {
        height: 100%;
        padding: 24px 0;
      }

      .price-title {
        text-align: center;
        font-size: 2rem;
        color: #4e5562;
        margin-bottom: 10px;
        font-weight: 400;
      }
      .pricing-content {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        height: 100%;
        strong {
          font-size: 1rem;
        }
        span {
          color: #4e5562;
        }

        .upgrade-btn {
          font-size: 1.1rem;
          width: 160px;
          height: 40px;
          border-radius: 6px;
          font-weight: 500;
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          border-color: var(--primary-bg-color);

          &:hover {
            background: var(--primary-bg-color);
            span {
              color: var(--white) !important;
            }
          }
        }
        .ant-radio-checked .ant-radio-inner {
          border-width: 5px;
        }
        .ant-radio-inner {
          &::after {
            display: none;
          }
        }
        .price {
          font-size: 2.2rem;
          font-weight: 600;
          color: var(--secondary-color);
          margin-bottom: 0;

          span {
            font-size: 0.8rem;
            font-weight: 400;
            color: var(--secondary-color);
          }
        }
        .more-price {
          margin-left: 25px;
          text-decoration: underline;
          cursor: pointer;
          font-size: 0.8rem;
        }
      }

      .pricing-footer {
        background-color: #f5f5f5;
        padding: 15px 20px;
        text-align: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-shadow: var(--box-shadow);
      }
    }

    .sub-badge {
      transition: all 0.5s ease;
      transform: scale(1) !important;
      cursor: pointer;

      &:hover {
        transform: scale(1.05) !important;
      }
      .ant-ribbon {
        height: 30px;
        line-height: 30px;
        padding: 0 9px;
      }
    }

    .subscription-plan-card {
      width: 420px;
    }
    .subscription-plan-card,
    .pro-plan-card {
      .pricing-content {
        margin-top: 0px;
        gap: 20px;
        .ant-space {
          margin-bottom: 5px;
        }
        .ant-space-item:first-child {
          min-width: 129px;
        }

        .upgrade-btn {
          margin-top: 15px;
          background: var(--primary-bg-color);
          span {
            color: var(--white);
          }
        }
      }
      .pricing-footer {
        bottom: -20px;
        div {
          font-size: 0.8rem;
        }
      }
    }
    .pro-plan-card {
      width: 420px;
      transform: scale(1) !important;
      cursor: pointer;

      &:hover {
        transform: scale(1.05) !important;
      }
      .pricing-content {
        padding-left: 25px;
        .upgrade-btn {
          margin-top: 30px;
          background: transparent;
          span {
            color: var(--primary-font-color);
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 996px) {
  .upgrade-plan-wrapper {
    .upgrade-plan-container {
      flex-direction: column;
      align-items: center;
      .sub-badge {
        width: 420px;
        margin-bottom: 20px;
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .upgrade-plan-wrapper {
    .upgrade-plan-container {
      margin-top: -140px;
      .sub-badge,
      .pro-plan-card,
      .subscription-plan-card {
        width: 380px;
      }
      .subscription-plan-card {
        height: 700px;
        .pricing-content {
          margin-left: 10px;
        }
      }
      .pro-plan-card {
        height: 680px;
      }
    }
    .pricing-header {
      .pricing-title {
        font-size: 2.2rem;
      }
      .plan-controler {
        font-size: 0.9rem;
      }
    }
  }
}
@media only screen and (max-width: 375px) {
  .upgrade-plan-wrapper {
    .upgrade-plan-container {
      margin-top: -140px;
      .sub-badge,
      .pro-plan-card,
      .subscription-plan-card {
        width: 350px;
      }
    }
    .pricing-header {
      .pricing-title {
        font-size: 2rem;
      }
      .plan-controler {
        font-size: 0.7rem;
      }
    }
  }
}
