.how-to-use-wrapper {
  .header {
    min-height: 300px;
    background-color: var(--mask-bg-color);
    width: 100%;
    .title {
      font-size: 3rem;
      font-weight: 600;
      position: relative;
      text-align: center;
      padding-top: 3rem;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        height: 8px;
        width: 60px;
        bottom: -10px;
        border-radius: 4px;
        background-color: #f5854a;
      }
    }
    .desc {
      color: var(--primary-font-color);
      font-size: 2.5rem;
      text-align: center;
    }
  }

  .use-demo-container {
    padding: 4rem 2rem;
    max-width: 1400px;
    margin: 0 auto;
    // background-color: #fff;
    // box-shadow: var(--box-shadow);
    // margin-top: -5rem;
    border-radius: var(--border-radius);
    margin-bottom: 4rem;

    .ant-steps-item:not(:last-child) {
      min-height: 90vh;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
      color: rgba(0, 0, 0, 0.5);
    }

    .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
      margin-right: 16px;
    }
    .ant-steps-item-wait .ant-steps-item-icon {
      border-color: rgba(0, 0, 0, 0.5);
    }
    .ant-steps-item-title {
      color: var(--primary-font-color) !important;
      font-size: 1rem;
      font-weight: 500;
    }
    .ant-steps-item-description {
      margin-top: 10px;
      color: var(--primary-font-color) !important;
      font-size: 1rem;
      padding-bottom: 20px;
    }
  }
}

@media only screen and (max-width: 426px) {
  .how-to-use-wrapper {
    .header {
      padding: 0 20px;
      .title {
        padding-top: 2rem;
      }
      .desc {
        color: var(--primary-font-color);
        font-size: 2rem;
        text-align: center;
      }
    }
    .use-demo-container {
      .ant-steps-item {
        min-height: 40vh;
      }
      .ant-steps-item-description {
        padding-bottom: 40px;
      }
    }
  }
}
