.free-ocr-wrapper {
  background-color: var(--secondary-bg-color);

  padding: 3rem 20px 0 20px;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  .app-title {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    color: var(--primary-font-color);
    line-height: 1;
  }
  .app-desc {
    font-size: 1.2rem;
    color: var(--primary-font-color);
    text-align: center;
    font-weight: 500;
  }
  .upload-file-container {
    margin-top: 1rem;
    padding: 10px 5px;
    border-radius: 20px;
    background-color: transparent;
    border: 2px dashed var(--dk-border-color);
    .upload-text {
      color: var(--primary-font-color);
      font-size: 15px;
      font-weight: bold;
    }
    .highlite-blue {
      color: var(--secondary-color);
      text-decoration: underline;
    }
    .upload-hint {
      margin: 0 auto;
      margin-top: 8px;
      font-size: 14px;
      color: var(--primary-font-color);
      opacity: 0.5;
      max-width: 800px;
    }
  }
}

@media only screen and (max-width: 426px) {
  .free-ocr-wrapper {
    padding: 40px 15px 60px 15px;
    .ant-upload.ant-upload-drag .ant-upload {
      padding: 5px 0;
      max-width: 300px;
      min-width: 280px;
    }
    .app-title {
      font-size: 2rem;
      line-height: 1.3;
    }
    .app-desc {
      margin-top: 5px;
      font-size: 1rem;
    }
    .upload-file-container {
      margin-top: 20px;
      padding: 0 20px;
      .upload-text {
        font-size: 13px;
        margin: 10px 0;
      }
      .highlite-blue {
        color: var(--secondary-color);
        text-decoration: underline;
      }
      .app-upload-box-content {
        flex-direction: column;
      }
    }
  }
}
