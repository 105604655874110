.footer-section {
  position: relative;
  .app-logo {
    width: 120px;
    & > img {
      width: 100%;
    }
  }

  .footer-container {
    background-color: var(--secondary-bg-color);
    width: 100%;
    padding: 3rem 0rem 0rem 0rem;
    .footer-sec-one {
      padding: 1rem 0;
    }
    .footer-sec-two {
      padding: 0 5rem 2rem 5rem;
      .footer-quote {
        font-size: 18px;
        font-weight: bold;
      }
      .email-icon {
        margin-right: 5px;
      }
      .phone-icon {
        margin-right: 5px;
        margin-top: -4px;
      }
      .contact-form {
        .contact-title {
          font-size: 17px;
          font-weight: bold;
          line-height: 1.5;
        }
        .contact-desc {
          font-size: 13px;
        }
        .mail-icon {
          margin: 5px 15px 0 5px;
          display: grid;
          place-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #0000001a;
          & > img {
            width: 15px;
          }
        }

        .submit-btn {
          border: none;
          float: right;
          background: #f5854a 0% 0% no-repeat padding-box;
          box-shadow: 0px 10px 20px #0000001a;
          border-radius: var(--border-radius);
          opacity: 1;
          height: 40px;
          width: 100%;
          &:hover {
            box-shadow: 0px 10px 20px #0000001a;
          }
        }

        #user {
          margin-top: 20px;
        }
        #user_name,
        #user_email,
        #user_contact {
          border-radius: var(--border-radius);
          height: 40px;
        }
        #user_requirement_desc {
          border-radius: var(--border-radius);
        }
      }
    }
    .footer-sec-bottom {
      padding: 16px 60px;
      background: var(--mask-bg-color);
    }
    .social-icons {
      margin-top: 15px;
    }
    .mobile-social-icon {
      display: none;
    }

    .select-language .ant-select-selector {
      border: none;
      border-radius: var(--border-radius);
      box-shadow: 0px 5px 8px #0000000d;
      .ant-select-selection-item {
        color: var(--primary-font-color);
        font-size: 0.8rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }
    .term-service-m-view {
      display: none;
    }

    .footer-year {
      color: #24253780;
    }

    .vt-line {
      color: #1890ff;
      color: #24253780;
    }

    .social-icon {
      &:hover {
        color: var(--primary-font-color);
      }
      cursor: pointer;
      width: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer-section {
    .footer-container {
      padding: 15px 0 0 0;
      .footer-sec-two {
        padding: 0 40px 2rem 40px;
      }
      .footer-sec-bottom {
        padding: 1rem 40px;
      }
      .term-service-m-view {
        font-size: 0.9rem;
        display: block;
      }
    }
    .terms-service,
    .vt-line {
      display: none;
    }
  }
}
@media only screen and (max-width: 426px) {
  .footer-section {
    .footer-container {
      padding: 15px 0 0 0;
      .footer-sec-two {
        padding: 0 20px 2rem 20px;
      }
      .footer-sec-bottom {
        padding: 1rem 20px;
      }
      .term-service-m-view {
        font-size: 0.9rem;
        display: block;
      }
    }
    .terms-service,
    .vt-line {
      display: none;
    }
  }
}
