.main-header {
  background-color: var(--white);
  padding: 0 40px;
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 100;
  .app-logo {
    cursor: pointer;
    width: 120px;
    & > img {
      width: 100%;
    }
  }
  .app-tag-line {
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-font-color);
  }
  .active-link {
    // border-bottom-color: var(--primary-font-color) !important;
    // color: var(--secondary-color) !important;
  }

  .vt-line {
    color: #24253780;
  }

  .d-nav-link {
    &:first-child {
      margin-right: 10px;
    }
    color: var(--primary-font-color);
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    padding: 0 10px;
    &:hover {
      opacity: 0.8;
    }
  }
  .user-dropdown-info {
    cursor: pointer;
    .user-name {
      font-size: 0.9rem;
      font-weight: 600;
      color: var(--primary-font-color);
      word-break: break-all;
    }
    .user-credit-info {
      font-size: 0.7rem;
      font-weight: 600;
      text-align: right;
      margin-right: 20px;
      opacity: 0.8;
      color: var(--secondry-font-color);
      .credit-logo {
        background-color: var(--lt-blue);
        color: var(--white) !important;
        border-radius: 50%;
        height: 18px;
        width: 18px;
        margin-right: 3px;
        display: inline-flex;
        align-items: center;
        padding-bottom: 1.5px;
        justify-content: center;
      }
    }
  }

  .extract-btn {
    color: var(--secondary-color);
    border-color: var(--secondary-color);
    font-size: 1.1rem;

    border-width: 1px;
    width: 150px;
    background: transparent;

    &:hover {
      color: #fff;
      background: var(--secondary-color);
    }
  }
  .header-btn {
    width: 180px;
    height: 40px;
    border-radius: 6px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .login-btn {
    font-size: 1rem;
    border-color: var(--primary-bg-color);
    background: var(--primary-bg-color);
    color: var(--white);
  }
  .mobile-menu {
    display: none;
  }
}

.fixed-header {
  box-shadow: 0px 3px 10px #0000001a;
}

.user-dropdown-menu {
  min-width: 200px;
  overflow: hidden;
  // border-radius: var(--border-radius) !important;
  box-shadow: 0px 10px 20px var(--lt-shadow-color);
  .ant-dropdown-menu {
    padding: 0;
  }
  .ant-dropdown-menu-item {
    padding: 7px 14px;
    &:hover {
      background: var(--secondary-bg-color);
    }
  }
  .ant-dropdown-menu-item-icon {
    font-size: 16px;
  }
  .ant-dropdown-menu-item-divider {
    margin: 0;
  }
  .ant-dropdown-menu-title-content {
    font-size: 0.8rem;
    font-weight: 500;
    color: var(--primary-font-color);
  }
}
@media only screen and (max-width: 1225px) {
  .main-header {
    .d-nav-link {
      display: none;
    }
    .mobile-menu {
      display: block;
    }
    .hamburger-icon {
      cursor: pointer;
      span {
        font-size: 1.2rem;
      }
    }
    .desktop-btns {
      display: none;
    }
  }
  .app-logo {
    cursor: pointer;
    width: 120px;
    & > img {
      width: 100%;
    }
  }
  .mobile-menu-container {
    z-index: 10000;
    .app-logo {
      cursor: pointer;
      width: 120px;
      & > img {
        width: 100%;
      }
    }
    .ant-drawer-body {
      padding: 15px 20px;
    }
    .nav-link {
      width: 100%;
      text-align: left;
      border-radius: var(--border-radius);

      color: var(--primary-font-color);
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      padding: 5px 8px;
    }
    .login-btn {
      margin: 20px auto 0 auto;
      font-size: 1.2rem;
      width: 400px;
      height: 40px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary-bg-color);
      background: var(--primary-bg-color);
      color: var(--white);
    }
    .logout-btn {
      font-size: 1.2rem;
      width: 100%;
      max-width: 450px;
      margin: 20px auto 0 auto;
      height: 40px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary-bg-color);
      color: var(--primary-font-color);
    }

    .user-name {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--primary-font-color);
      padding-left: 10px;
      padding-top: 5px;
      word-break: break-all;
    }
    .user-credit-info {
      font-size: 1rem;
      font-weight: 600;
      opacity: 0.8;
      color: var(--secondry-font-color);
      padding-left: 10px;
      margin-top: 5px;
      .credit-logo {
        background-color: var(--lt-blue);
        color: var(--white) !important;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin-right: 3px;
        display: inline-flex;
        align-items: center;
        padding-bottom: 1.7px;
        justify-content: center;
      }
    }
  }

  .mobile-menu-container {
    .ant-drawer-close {
      padding: 0;
    }
    .anticon-close {
      background: none !important;
      color: #000;
      font-size: 1.5rem;
      padding: 15px 20px;
    }
  }
}
@media only screen and (max-width: 996px) {
  .main-header {
    .d-nav-link {
      display: none;
    }
    .mobile-menu {
      display: block;
      .hamburger {
        .user-name {
          font-size: 14px;
          color: var(--primary-font-color);
          font-weight: 500;
        }
      }
    }
    .hamburger-icon {
      cursor: pointer;
      font-size: 2rem;
    }
    .desktop-btns {
      display: none;
    }
  }
  .app-logo {
    cursor: pointer;
    width: 120px;
    & > img {
      width: 100%;
    }
  }
  .mobile-menu-container {
    z-index: 10000;
    .app-logo {
      cursor: pointer;
      width: 120px;
      & > img {
        width: 100%;
      }
    }
    .ant-drawer-body {
      padding: 15px 20px;
    }
    .login-btn {
      margin-top: 20px;
      font-size: 1.2rem;
      width: 100%;
      height: 40px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary-bg-color);
      background: var(--primary-bg-color);
      color: var(--white);
    }
    .logout-btn {
      font-size: 1.2rem;
      width: 100%;
      max-width: 450px;
      margin: 20px auto 0 auto;
      height: 40px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary-bg-color);
      color: var(--primary-font-color);
    }

    .user-name {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--primary-font-color);
      padding-left: 10px;
      padding-top: 5px;
    }
    .user-credit-info {
      font-size: 1rem;
      font-weight: 600;
      opacity: 0.8;
      color: var(--secondry-font-color);
      padding-left: 10px;
      margin-top: 5px;
      .credit-logo {
        background-color: var(--lt-blue);
        color: var(--white) !important;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin-right: 3px;
        display: inline-flex;
        align-items: center;
        padding-bottom: 1.7px;
        justify-content: center;
      }
    }
  }

  .mobile-menu-container {
    .ant-drawer-close {
      padding: 0;
    }
    .anticon-close {
      background: none !important;
      color: #000;
      font-size: 1.5rem;
      padding: 15px 20px;
    }
  }
}
@media only screen and (max-width: 426px) {
  #main-header,
  .main-header {
    padding: 0 20px;
    .desktop,
    .vt-line {
      display: none;
    }
    .app-title-mobile {
      flex-direction: column;
      align-items: flex-start;
    }
    .mobile-menu {
      display: block;
    }
    .hamburger-icon {
      font-size: 2rem;
    }
  }

  .app-logo {
    cursor: pointer;
    width: 120px;
    & > img {
      width: 100%;
    }
  }
  .mobile-menu-container {
    z-index: 10000;
    .app-logo {
      cursor: pointer;
      width: 120px;
      & > img {
        width: 100%;
      }
    }
    .ant-drawer-body {
      padding: 15px 20px;
    }

    .login-btn {
      margin-top: 20px;
      font-size: 1.2rem;
      width: 100%;
      height: 40px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary-bg-color);
      background: var(--primary-bg-color);
      color: var(--white);
    }
    .logout-btn {
      margin-top: 20px;
      font-size: 1.2rem;
      width: 100%;
      height: 40px;
      border-radius: 6px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      border-color: var(--primary-bg-color);
      color: var(--primary-font-color);
    }

    .user-name {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--primary-font-color);
      padding-left: 10px;
      padding-top: 5px;
    }
    .user-credit-info {
      font-size: 1rem;
      font-weight: 600;
      opacity: 0.8;
      color: var(--secondry-font-color);
      padding-left: 10px;
      margin-top: 5px;
      .credit-logo {
        background-color: var(--lt-blue);
        color: var(--white) !important;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        margin-right: 3px;
        display: inline-flex;
        align-items: center;
        padding-bottom: 1.7px;
        justify-content: center;
      }
    }
  }
}
