.chrome-install-banner {
  height: 140px;
  width: 340px;
  .ant-notification-notice-close {
    display: none;
  }
  .banner-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    color: var(--primary-font-color);
  }
  .chrome-banner-btns {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 10px;
    .no-thanks-btn {
      margin-left: 20px;

      font-size: 14px;
      color: var(--lt-gray-label);
      font-weight: 500;
      &:hover {
        color: var(--primary-font-color);
      }
    }
    .install-btn {
      border-radius: 4px;
      font-size: 16px;
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--dk-orange);
      border-color: var(--dk-orange);
    }
  }
}
