.hero-banner-wrapper {
  height: 500px;
  position: relative;
  // background-image: url("../../assest/mask-bg-header.svg");
  background-repeat: no-repeat;
  background-color: var(--mask-bg-color);
  .curve-wave {
    margin-top: -90px;
  }
  .banner-content {
    position: relative;
    padding-top: 40px;
    max-width: 1250px;
    margin: 0 auto;
    .arrow-line-down-img {
      position: absolute;
      right: -120px;
      top: 190px;
    }
  }
  .banner-title {
    color: var(--primary-font-color);
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 5px;
    line-height: 1.3;
    margin-bottom: 20px;
  }
  .banner-sub-title {
    color: var(--primary-font-color);
    font-size: 1.2rem;
    margin-bottom: 40px;
    max-width: 350px;
  }
  .banner-desc {
    color: var(--primary-font-color);
    font-size: 1rem;
    margin: 10px 0 40px 0;
  }
  .hero-carousel {
    width: 420px;
    overflow: hidden;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
  .banner-img {
    width: 420px;
    height: 300px;
    img {
      height: 100%;
      width: 100%;
    }
  }
  .browse-img-btn {
    width: 130px;
    height: 45px;
    border-radius: var(--border-radius);
    background: #f5854a;
    color: #fff;
    font-size: 0.8rem;
    box-shadow: 0px 10px 20px #0000001a;

    &:hover,
    &:focus,
    &:active {
      border-color: #f5854a;
    }
  }
  .dummy-upload-container {
    background: none;
    border: none;
    width: 400px;
  }
}

@media only screen and (max-width: 1225px) {
  .hero-banner-wrapper {
    padding: 20px 15px 30px 15px;
    height: 420px;
    .banner-content {
      justify-content: space-between;
      .ant-col:first-child {
        align-self: flex-end;
      }
      .ant-row {
        justify-content: stretch;
      }
    }
    .dummy-upload-container {
      margin-top: 20px;
      .upload-dummy-box {
        padding: 20px 15px;
      }
    }
    .banner-title {
      font-size: 3rem;
      padding-left: 25px;
    }
    .banner-sub-title {
      padding-left: 25px;
    }

    .hero-carousel {
      display: none;
    }

    .arrow-line-down-img,
    .banner-img,
    .curve-wave {
      display: none;
    }
  }
}
@media only screen and (max-width: 1025px) {
  .hero-banner-wrapper {
    .banner-content {
      .ant-row {
        justify-content: stretch;
        width: 100%;
        .ant-col {
          width: 100%;
        }
      }
    }
    .dummy-upload-container {
      width: 350px;
    }
  }
}
@media only screen and (max-width: 769px) {
  .hero-banner-wrapper {
    padding: 20px 15px 30px 15px;
    height: 600px;
    .banner-content {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .ant-col:first-child {
        align-self: unset;
      }
      .ant-col {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
    }
    .dummy-upload-container {
      margin: 20px auto 0 auto;

      .upload-dummy-box {
        padding: 20px 15px;
      }
    }
    .banner-title {
      display: grid;
      place-items: center;
      font-size: 3rem;
      text-align: center;
      padding-left: 0;
    }
    .banner-sub-title {
      display: grid;
      place-items: center;
      margin: 0 auto;
      padding-left: 0;
      .ant-space-item {
        text-align: center;
      }
    }

    .arrow-line-down-img,
    .banner-img,
    .curve-wave {
      display: none;
    }
  }
}

@media only screen and (max-width: 426px) {
  .hero-banner-wrapper {
    padding: 20px 30px 30px 30px;
    height: 420px;
    .dummy-upload-container {
      margin-top: 20px;
      width: 100%;
      .upload-dummy-box {
        padding: 20px 15px;
      }
    }
    .banner-title {
      font-size: 2.5rem;
      display: grid;
      place-items: center;
      text-align: center;
    }
  }
}
@media only screen and (max-width: 376px) {
  .hero-banner-wrapper {
    padding: 0px 30px 30px 30px;

    .banner-title {
      font-size: 2.2rem;
    }
  }
}

@media only screen and (max-width: 331px) {
  .hero-banner-wrapper {
    .banner-title {
      font-size: 2.2rem;
    }
  }
}
