.password-reset-wrapper {
  height: 100vh;
  position: relative;
  .ant-alert {
    border-radius: var(--border-radius);
    margin-bottom: 20px;
  }
  .anticon-close {
    background-color: transparent !important;
  }
  .header_container {
    padding: 0 3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    height: 60px;

    .app-logo {
      width: 120px;
      & > img {
        width: 100%;
      }
    }

    .vt-line {
      color: #24253780;
    }

    .app-header-desc {
      color: #313740;
      font-size: 15px;
      font-weight: bold;
    }

    .header-quote {
      color: var(--primary-font-color);
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
  .password-rest-wrapper {
    margin: 0 auto 0 auto;

    max-width: 500px;
    h1 {
      margin: 0 auto 30px auto;
    }
    padding: 100px 20px 0 20px;
    display: flex;
    flex-direction: column;

    .back-to-home {
      color: var(--secondary-color);
      cursor: pointer;
      margin-top: 20px;
      text-align: center;
    }
  }
  #password-reset-form {
  }

  .footer-sec-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem 3rem;
    background: var(--mask-bg-color);
  }
  .social-icons {
    margin-top: 15px;
  }
  .mobile-social-icon {
    display: none;
  }
}
