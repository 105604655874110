.tools-api-wrapper {
  display: flex;
  padding: 30px 0;

  .side-bar-topics {
    max-width: 250px;
    min-height: 500px;
    .side-bar-title {
      padding: 0 20px 10px 20px;
      font-size: 1.5rem;
      font-weight: 600;

      color: var(--primary-font-color);
    }
    .topic-menus {
      width: 250px;
      height: 100%;
      background: transparent;
      .ant-menu-title-content {
        font-weight: 500;
        font-size: 1rem;
      }
    }
  }

  .topic-content-wrapper {
    max-height: 90vh;
    overflow-y: auto;
    scroll-behavior: smooth;
    // padding-top: 40px;
    width: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    .topic-content {
      padding: 0 20px;
      .side-bar-title {
        display: none;
      }
      section {
        display: flex;
        border-bottom: 1px solid #dee2e6 !important;
        padding-bottom: 20px;
        &:not(:first-child) {
          padding-top: 20px;
        }
      }
      .topic-content-left {
        // flex-basis: 55%;
        h1 {
          font-size: 2.5rem;
          font-weight: 700;
          line-height: 1.3;
          color: var(--primary-font-color);
          margin-bottom: 10px;
          max-width: 500px;
        }
        h3 {
          font-weight: 600;
          font-size: 1.1rem;
          margin-bottom: 0;
        }
        .desc {
          font-size: 1.25rem;
          font-weight: 400;
        }
        .desc-second {
          font-size: 1rem;
          font-weight: 400;
          margin-bottom: 10px;
        }
        .generate-api-key-btn {
          border-radius: 4px;
          height: 40px;
        }
        .lead {
          margin-top: 20px;
          font-size: 1rem;
          font-weight: 500;
        }
        ul {
          list-style: decimal;
          li {
            font-size: 1.1rem;
            margin-bottom: 10px;
          }
        }
        .hint-link {
          color: var(--primary-font-color-lt);
          font-weight: 400;
          margin-top: 25px;
        }
      }
      .topic-content-right {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .tools-api-wrapper {
    .topic-content-wrapper {
      .topic-content {
        .topic-content-left {
          h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .tools-api-wrapper {
    .side-bar-topics {
      display: none;
    }

    .topic-content-wrapper {
      padding-top: 0;
      max-height: initial;
      overflow-y: initial;
      padding-left: 20px;
      padding-right: 20px;
      .topic-content {
        .side-bar-title {
          display: block;
          font-size: 2rem;
          font-weight: 600;
          text-align: left;
        }
        .topic-content-left {
          h1 {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 424px) {
  .tools-api-wrapper {
    .topic-content-wrapper {
      padding-top: 0;
      max-height: initial;
      overflow-y: initial;
      padding-left: 10px;
      padding-right: 10px;
      .topic-content {
        .side-bar-title {
          display: block;
          font-size: 2rem;
          font-weight: 600;
          text-align: left;
        }
        .topic-content-left {
          h1 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 375px) {
}
