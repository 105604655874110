.login-modal-wrapper {
  height: 100%;
  .left-content {
    padding: 40px 50px;
    background-color: var(--mask-bg-color);
    h1 {
      margin: 100px 0 20px 0;
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary-font-color);
    }
    p {
      font-size: 0.8rem;
      color: var(--primary-font-color);
    }
  }
  .right-content {
    padding: 30px 60px;
    h1 {
      font-size: 1.5rem;
      margin-bottom: 25px;
      color: var(--primary-font-color);
    }
    p {
      font-size: 0.9rem;
      color: var(--primary-font-color);
      margin-bottom: 20px;
    }
    .login-btn {
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .forgot-password,
    .back-to-login {
      color: var(--secondary-color);
      cursor: pointer;
    }
    .social-login-btn {
      font-size: 1rem !important;
      border-radius: var(--border-radius) !important;
      box-shadow: none !important;
      height: 45px !important;
      border: 1px solid #76b2e8 !important;
      &:hover,
      :focus,
      :active {
        background: none !important;
        border-color: var(--secondary-color) !important;
      }
    }
    .microsoft-btn {
      background-color: transparent !important;
      color: var(--primary-font-color) !important;
    }
    .ant-btn-primary[disabled] {
      box-shadow: var(--box-shadow);
    }
  }
  .ant-alert {
    border-radius: var(--border-radius);
    margin-bottom: 5px;
  }
  .anticon-close {
    background-color: transparent !important;
  }

  .forgot-ps-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 80px 80px 0 80px !important;
    h1 {
      margin-bottom: 5px;
    }
    .back-to-login {
      text-align: center;
    }
  }
  .regiter-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .forgot-password,
    .click-to-register {
      font-size: 0.8rem;
    }

    .click-to-register {
      span {
        color: var(--secondary-color);
        cursor: pointer;
      }
    }
  }

  .click-to-sign {
    text-align: center;
    span {
      color: var(--secondary-color);
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .left-content {
    display: none;
  }
  .login-modal-wrapper {
    .right-content {
      width: 100%;
      padding: 20px 25px;
      h1 {
        font-size: 1.5rem;
        color: var(--primary-font-color);
      }
      p {
        font-size: 0.9rem;
        color: var(--primary-font-color);
        margin-bottom: 20px;
      }
      .login-btn {
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
    .regiter-wrapper {
      .forgot-password,
      .click-to-register {
        font-size: 0.7rem;
      }
    }
    .forgot-ps-container {
      padding: 80px 25px !important;
      .back-to-login {
        text-align: center;
      }
    }
  }

  .ant-modal {
    top: 30px !important;
  }
}
