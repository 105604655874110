.faq-container {
  padding: 3rem 5rem;
  background-image: url("../../assest/mask-group-3.svg");
  background-repeat: no-repeat;
  background-position: center;

  .app-guids {
    h2 {
      font-size: 2rem;
      font-weight: bold;
    }
    p {
      color: #313740;
      font-weight: 400;
      span {
        color: #000;
        font-weight: 500;
      }
    }
    .text-dark {
      color: #000;
      font-weight: 500;
    }
  }

  .feedback-btn {
    // border-color: #f5854a;
    border: none;
    float: right;
    background: #fff;
    box-shadow: 0px 10px 20px #0000001a;
    border-radius: var(--border-radius);
    opacity: 1;
    color: var(--primary-font-color);
    font-size: 1.1rem;
    height: 40px;
    width: 100%;
    &:hover {
      box-shadow: 0px 10px 20px #0000001a;
      background: #f5854a 0% 0% no-repeat padding-box;
      color: #fff;
    }
    &:focus {
      border-color: #f5854a;
    }
  }

  .faq-section {
    border-radius: 8px;
    padding: 2rem 2.5rem;
    background-image: url("../../assest/mask-group-3.svg");
    background-color: var(--white);

    box-shadow: 0px 10px 20px #0000001a;
    opacity: 1;
    .faq-title {
      font-size: 2rem;
      font-weight: 600;
      margin-bottom: 50px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        height: 8px;
        width: 50px;
        bottom: -10px;
        border-radius: 4px;
        background-color: #f5854a;
      }
    }
    .faq-accordian {
      padding-bottom: 30px;
      background-color: var(--white);

      .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        padding: 18px 0;
        background-color: var(--white);
        .faq-question {
          padding-right: 5px;
          font-size: 0.9rem;
          font-weight: 500;
          color: #000;
          margin-bottom: 0;
        }
      }
      .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
        font-size: 1.6rem;
        color: var(--primary-font-color);
      }
      .ant-collapse-content > .ant-collapse-content-box {
        // background-color: var(--secondary-bg-color);
        background-color: var(--white);
        padding: 0px 0 15px 0;
        p {
          padding: 0 !important;
          font-size: 0.9rem;
          color: var(--primary-font-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .faq-container {
    padding: 20px 15px;
    .faq-accordian {
      .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
        .faq-question {
          font-size: 0.8rem;
          max-width: 290px;
        }
      }
    }
    .faq-section {
      padding: 1.5rem 1rem;
    }
  }
}
