.home-page-wrapper {
  background-color: var(--secondary-bg-color);

  .contact-us-link {
    color: var(--secondary-color);
    cursor: pointer;
  }
  .left-credit {
    color: red;
  }
  .main-content {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    min-height: 500px;
  }
  .app-title {
    text-align: center;
    display: block;
    font-size: 2.5rem;
    color: var(--primary-font-color);
    line-height: 1;
  }
  .app-desc {
    font-size: 1.2rem;
    color: var(--primary-font-color);
    text-align: center;
    font-weight: 500;
  }
  .upload-file-container {
    margin-top: 1rem;
    padding: 10px 5px;
    border-radius: 20px;
    background-color: transparent;
    border: 2px dashed var(--dk-border-color);
    .upload-text {
      color: var(--primary-font-color);
      font-size: 15px;
      font-weight: bold;
    }
    .highlite-blue {
      color: var(--secondary-color);
      text-decoration: underline;
    }
    .upload-hint {
      margin: 0 auto;
      margin-top: 8px;
      font-size: 14px;
      color: var(--primary-font-color);
      opacity: 0.5;
      max-width: 800px;
    }
  }
}
.conatact-us-modal {
  padding: 40px;
  .constact-modal-title {
    text-align: center;
    color: var(--primary-font-color);
  }
  .contact-us-btn {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 426px) {
  .home-page-wrapper {
    .main-content {
      padding: 40px 15px 20px 15px;
    }
    .app-title {
      margin: 0;
      font-size: 2rem;
      line-height: 1.3;
    }
    .app-desc {
      font-size: 1.2rem;
      padding: 15px 0;
    }
    .upload-file-container {
      .ant-upload {
        padding: 10px;
        min-width: 280px;
        max-width: 350px;
      }

      .app-upload-box-content {
        flex-direction: column;

        .upload-text {
          padding: 10px 0;
        }
      }
    }
  }

  .conatact-us-modal {
    padding: 40px 20px;
  }
}
