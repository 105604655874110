.client-feedback-wrapper {
  padding: 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .feedback-carousel-box {
    padding: 30px 20px 20px 20px;
    width: 600px;
    min-height: 320px;
    background-color: var(--white);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    .person-name {
      font-size: 1.2rem;
      font-weight: 600;
      color: var(--primary-font-color);
      text-align: center;
    }
    .person-title {
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary-font-color);
      text-align: center;
    }
    .client-feedback {
      padding: 0 20px;
      text-align: center;
      color: var(--primary-font-color);
      opacity: 0.5;
      font-size: 1rem;
      font-weight: 500;
    }

    .slick-dots {
      li {
        button {
          height: 100%;
          border-radius: var(--border-radius);
          border: 2px solid var(--lt-gray-label);
          border-radius: 50%;
        }
        width: 15px;
        height: 15px;
        margin: 0 5px;
      }
      .slick-active {
        button {
          background-color: var(--secondary-color);
          border: 2px solid var(--secondary-color);
        }
      }
    }
  }
}

@media only screen and (max-width: 426px) {
  .client-feedback-wrapper {
    padding: 2rem 15px 3rem 15px;
    .section-title {
      font-size: 1.8rem;
    }
    .feedback-carousel-box {
      max-width: 390px;
      padding: 20px 10px;
      width: 600px;
      min-height: 340px;
      .client-feedback {
        padding: 0;
      }
    }
    .slick-dots {
      padding-top: 20px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .client-feedback-wrapper {
    .feedback-carousel-box {
      max-width: 350px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .client-feedback-wrapper {
    .feedback-carousel-box {
      max-width: 340px;
      .person-name {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 331px) {
  .client-feedback-wrapper {
    padding: 1rem 15px 3rem 15px;
    .section-title {
      font-size: 1.6rem;
    }
    .feedback-carousel-box {
      max-width: 300px;
      .person-name {
        text-align: center;
      }
    }
  }
}
