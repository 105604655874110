.file-processing-wrapper {
  margin: 1.5rem auto 2rem auto;
  .file-name {
    color: var(--primary-font-color);
    font-weight: 600;
    font-size: 0.8rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .view-btn,
  .delete-btn {
    border-radius: 4px;
    border-color: var(--lt-blue-border-color);
  }
  .get-orc-btn {
    width: 250px;
    height: 40px;
    margin-top: 0;
    border: none;
    box-shadow: 0px 10px 20px var(--lt-shadow-color);
    border-radius: var(--border-radius);
    opacity: 1;
    font-weight: 600;
    &:hover {
      box-shadow: 0px 10px 20px var(--lt-shadow-color);
    }
  }
  .btn-active {
    color: var(--white);
    background-color: var(--dk-orange);
  }
  .marginTop {
    transform: translate(0, -72px);
  }
  .file-view-box {
    border-radius: var(--border-radius);
    box-shadow: 0px 10px 20px var(--lt-shadow-color);
    background-color: var(--white);
    overflow: hidden;
    .file-view-header {
      background-color: var(--white);
      padding: 15px 20px 5px 20px;
      .mode-check-boxs-all {
        margin-left: 15px;
        padding-left: 15px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        border-right: 1px solid rgba(0, 0, 0, 0.1);
      }
      .process-cancel-btn {
        font-size: 0.7rem;
        box-shadow: none;
      }
      .no-of-files {
        color: var(--primary-font-color);
        font-size: 0.8rem;
        font-weight: 500;
        opacity: 0.5;
      }
      .convert-format {
        width: 100%;
        .ant-space {
          float: right;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
    }
    .file-view-cards {
      position: relative;
      min-height: 400px;

      .file-view-card {
        position: relative;
        overflow: hidden;
        padding: 0 20px;

        .disabled-overlay {
          position: absolute;
          width: 100%;
          height: 100%;
          z-index: 5;
          background-color: #ffffffc9;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--lt-green);
          font-size: 1rem;
          font-weight: 600;
        }

        .pages-label {
          color: var(--primary-font-color);
          font-size: 0.8rem;
          opacity: 0.5;
          font-weight: 600;
        }
        .pdf-page-input {
          border-radius: var(--border-radius);
          height: 30px;
          margin-left: 4px;
        }
        .status-tag {
          border-radius: 4px;
          font-weight: 500;
          padding: 2px 10px;
        }
        .ant-progress-bg {
          height: 3px !important;
        }
      }

      .convert-format {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
        justify-content: flex-end;
        .format-label {
          margin-right: 8px;
        }
      }
    }
    .checkbox-label,
    .format-label {
      color: var(--primary-font-color);
      font-size: 0.8rem;
      font-weight: 500;
      opacity: 0.9;
    }
    .select-format {
      float: right;
    }

    .select-format .ant-select-selector {
      border-radius: var(--border-radius);
      border: 1px solid #24253734;
      &:focus,
      &:active {
        border-color: #24253734;
      }
      .ant-select-selection-item {
        color: var(--primary-font-color);
        font-size: 0.8rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }

  .file-result-box {
    min-height: 400px;
    max-height: 400px;
    .processing-wait-card {
      margin-bottom: 20px;
      border-radius: var(--border-radius);
      background: var(--white);
      box-shadow: 0px 10px 20px var(--lt-shadow-color);
      text-align: center;
      height: 80px;
      line-height: 80px;
      color: var(--dk-gray-04);
      width: 100%;
      span {
        opacity: 0.5;
      }
    }
    .error-msg-card {
      color: red;
      font-size: 1.2rem;
      span {
        opacity: 1 !important;
      }
    }
    .pdf-view-container {
      display: flex;
      justify-content: center;
      height: 490px;
      max-width: 900px;

      overflow: auto;
      .react-pdf__Page__annotations {
        display: none;
      }
    }

    .border-arrow-dases {
      margin: 35px 0 0 -80px;
      width: 230px;
      transform: rotate(-20deg);
    }
    .border-arrow-dases-2 {
      margin: 30px 0 0 -80px;
      width: 280px;
      transform: rotate(-15deg);
    }

    .file-result-view-card {
      margin-bottom: 20px;
      border-radius: var(--border-radius);
      background: var(--white);
      box-shadow: 0px 10px 20px var(--lt-shadow-color);
    }
    .paid-orc-result-container {
      padding: 15px 20px;
      height: 80px;
    }
    .free-ocr-result-container {
      overflow: hidden;
      .file-result-header {
        padding: 15px;
        background-color: #f5f5f5;
        .result-title {
          text-align: left;
          font-size: 1rem;
          margin: 0;
        }
        .copy-txt-btn,
        .down-txt-btn {
          border: none;
          box-shadow: 0px 5px 10px #0000000d;
          border: 1px solid #2425371a;
          border-radius: 8px;
          color: #376dc6;
          font-size: 12px;
        }
      }
      .free-ocr-result-text {
        padding: 15px;
        max-height: 550px;
        width: 100%;
        overflow: auto;
        .page-no:not(:fist-child) {
          margin-bottom: 0;
          margin-top: 10px;
        }
        .text-result {
          font-size: 16px;
          letter-spacing: 0px;
          white-space: pre-wrap;
          color: #242538;
          opacity: 1;
          margin-bottom: 1px;
        }
      }
    }
  }
}
.custom-btn-style {
  border: none;
  background: var(--dk-orange);
  box-shadow: 0px 10px 20px var(--lt-shadow-color);
  border-radius: var(--border-radius);
  color: var(--white);
  height: 30px;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 10px 20px var(--lt-shadow-color);
    background: var(--dk-orange);
  }
}
.freeOCR {
  max-width: 1400px;
  .file-view-box {
    .file-view-header {
      padding: 10px 20px;
      background-color: #f5f5f5;

      .view-action-btn {
        border: none;
        box-shadow: 0px 5px 10px #0000000d;
        border: 1px solid #2425371a;
        border-radius: 8px;
        color: #376dc6;
        font-size: 12px;
      }
    }

    .pdf-view-container {
      height: 550px;
      max-height: 550px;
      overflow: auto;
      max-width: 900px;

      .react-pdf__Page__annotations {
        display: none;
      }
      .react-pdf__Document > .react-pdf__Page {
        justify-content: initial !important;
      }
    }
  }
  .get-orc-btn {
    margin-top: 20px;
  }
  .file-upload-mode {
    .checkbox-label {
      color: #181819;
      font-weight: 500;
      font-size: 0.9rem;
    }
    .border-arrow-dases {
      z-index: 1;
      margin: 30px 0 0 -60px;
      width: 200px;
      transform: rotate(-20deg);
    }
  }
  .time-taken-view {
    margin-top: 2rem;
    .time-taken-label {
      color: #181819;
      font-weight: 600;
      font-size: 14px;
    }
    .time-taken {
      color: #376dc6;
      font-weight: 600;
      margin-top: -5px;
    }
  }
}
.paidOCR {
  max-width: 1200px;
  .file-result-box {
    margin-top: 80px;
  }
}
.pdf-view-modal {
  .pdf-view-container {
    height: 490px;
    max-height: 550px;
    overflow: auto;
    max-width: 900px;
    .react-pdf__Page__annotations {
      display: none;
    }
  }
  padding-top: 10px;
}

.prv-btn,
.next-btn {
  border-radius: 4px;
  border: none;
  // box-shadow: 0px 10px 20px var(--lt-shadow-color);
}
.next-btn {
  float: right;
}
.align-right {
  float: right;
}

.react-pdf__Document > .react-pdf__Page {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1224px) {
  .freeOCR {
    max-width: 1040px;
    .get-orc-btn {
      width: 180px !important;
    }
    .file-upload-mode {
      .border-arrow-dases {
        margin: 30px 0 0 -80px;
      }
    }

    .pdf-view-modal {
      .ant-col:first-child {
        order: 1 !important;
        .ant-row {
          align-items: center;
          .ant-col:nth-child(3) {
            order: 3;
            margin-top: 0px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .paidOCR {
    margin: 0 25px;
  }
}
@media only screen and (max-width: 980px) {
  .freeOCR {
    max-width: 800px;
    margin: 0 auto 0 auto;
    .get-orc-btn {
      width: 180px !important;
    }
    .file-upload-mode {
      .border-arrow-dases {
        margin: 30px 0 0 -80px;
      }
    }

    .file-view-box {
      .file-view-header {
        .ant-col:first-child {
          order: 1;
        }
        .ant-col:nth-child(2) {
          display: flex;
          justify-content: flex-end;
        }
      }
    }

    .ant-row {
      .ant-col:first-child {
        order: 2;
      }
      .ant-col:nth-child(2) {
        order: 1;

        .process-controler-row {
          margin-bottom: 30px;
          .ant-col:first-child {
            width: 100%;
            order: 1;
            .file-upload-mode {
              width: 100%;
              .border-arrow-dases {
                display: none;
              }
              & > div {
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .get-orc-btn {
                  width: 250px !important;
                  float: right;
                  margin-top: 0;
                }
              }
            }
          }
          .ant-col:nth-child(2) {
            order: 2;
            margin-bottom: 0;
            .time-taken-view {
              margin-top: 0.5rem;
            }
          }
        }
      }
      .ant-col:nth-child(3) {
        order: 3;
        margin-top: 50px;
        margin-bottom: 150px;
        .free-ocr-result-container {
          .ant-row {
            .ant-col:first-child {
              order: 1;
            }
          }
        }
      }
    }

    .pdf-view-modal {
      .ant-col:first-child {
        order: 1 !important;
      }
    }
  }

  .paidOCR {
    margin: 0 15px;
    .file-view-box {
      margin-bottom: 20px;
    }
    .file-result-box {
      margin-top: 0;
    }
  }
  .file-processing-wrapper {
    .marginTop {
      transform: translate(0, 0) !important;
    }
  }

  .file-processing-wrapper .file-view-box .file-view-cards {
    min-height: 100%;
  }
  .file-processing-wrapper .file-result-box {
    display: flex;
    align-items: center;
    flex-direction: column;
    .file-result-view-card {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 426px) {
  .freeOCR {
    .ant-row {
      .ant-col:first-child {
        order: 2;
        .file-view-header {
          .ant-col:first-child {
            order: 1;
          }
        }
      }
      .ant-col:nth-child(2) {
        order: 1;

        .process-controler-row {
          margin-bottom: 10px;
          padding: 0 20px;
          .ant-col:first-child {
            width: 100%;
            order: 1;
            .file-upload-mode {
              width: 100%;
              .border-arrow-dases {
                display: none;
              }
              & > div {
                width: 100%;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
                .get-orc-btn {
                  width: 280px !important;
                  float: right;
                  margin: 15px 0;
                }
              }
            }
          }
          .ant-col:nth-child(2) {
            order: 2;
            margin-bottom: 0;
            .time-taken-view {
              margin-top: 0.5rem;
            }
          }
        }
      }
      .ant-col:nth-child(3) {
        order: 3;
        margin-top: 5px;
        margin-bottom: -100px;
        .free-ocr-result-container {
          .ant-row {
            .ant-col:first-child {
              order: 1;
            }
          }
        }
      }
    }
  }
  .paidOCR {
    margin: 0 5px;
    .file-result-box {
      min-height: auto !important;
      .file-name {
        max-width: 200px !important;
      }
    }
  }
  .freeOCR .file-view-box .pdf-view-container {
    height: auto;
  }
}

@media only screen and (max-width: 426px) {
  .file-processing-wrapper {
    .w-100 {
      width: 100%;
    }
    .file-view-box {
      .file-view-header {
        .mode-check-boxs-all {
          margin-left: 0;
          padding-left: 0;
          .checkbox-label {
            font-size: 0.7rem;
          }
          border: none;
        }
        .convert-format {
          width: 100%;
          .ant-space {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
      .file-view-cards {
        min-height: auto;
        .img-page-select-box {
          display: none;
        }
        .mode-check-boxs {
          margin-top: 10px;
        }
        .convert-format {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;
          .ant-space {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    .border-arrow-dases {
      display: none;
    }
    .file-upload-mode {
      display: grid;
      place-items: center;
      margin-top: 20px;
    }
    .file-result-box {
      margin-top: 20px;
      min-height: 600px;
      .processing-wait-card {
        margin-top: 10px;
      }
      .free-ocr-result-container {
        .file-result-header {
          padding: 15px 5px;
          .down-txt-btn,
          .copy-txt-btn {
            padding: 0 8px;
          }
          .result-title {
            font-size: 1rem;
            // padding-left: 5px;
          }
        }
        .free-ocr-result-text {
          max-height: 450px;
        }
      }
    }
    .freeOCR .time-taken-view {
      margin: 1rem auto !important;
    }
  }

  .msg-modal {
    top: 0px !important;
    .ant-modal-confirm-btns {
      .ant-btn {
        border-radius: var(--border-radius);
      }
    }
  }
  .mobile-d-none {
    display: none;
  }
}
