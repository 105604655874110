.dashboard-wrapper {
  padding: 3rem 5rem 2rem 5rem;
}

.page-title {
  font-size: 2rem;
  color: var(--primary-font-color);
  font-weight: 600;
  margin: 0 auto;
  max-width: 1210px;
  margin-top: -20px;
}

.user-account-wrapper {
  display: block;
  border-radius: var(--border-radius);
  box-shadow: 0px 10px 20px var(--lt-shadow-color);
  margin: 2rem auto;
  background-color: var(--white);
  overflow: hidden;
  max-width: 1210px;

  .user-personal-info {
    padding: 20px;
    border-right: 1px solid var(--border-color);
    min-height: 500px;
    height: 100%;
    background-color: var(--mask-bg-color);
    .user-info-top {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 30px;

      .user-name {
        font-size: 1rem;
        font-weight: 600;
        padding: 10px 0;
        text-align: center;
        color: var(--primary-font-color);
        word-break: break-all;
      }
      .user-credit-balance {
        border: 1px solid var(--border-color);
        padding: 10px 20px;
        border-radius: var(--border-radius);
        color: var(--secondary-color);
        font-size: 0.7rem;
        font-weight: 600;
        background-color: var(--white);
        .credit-logo {
          background-color: var(--lt-blue);
          color: var(--white) !important;
          border-radius: 50%;
          height: 18px;
          width: 18px;
          margin-right: 3px;
          display: inline-flex;
          align-items: center;
          padding-bottom: 1.5px;
          justify-content: center;
        }
      }
      .upgrade-plan-btn {
        margin-top: 10px;
      }
    }
    .user-details {
      .user-detail-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detail-label {
          font-size: 1rem;
          font-weight: 500;
          color: var(--primary-font-color);
        }
      }
      .user-name {
        word-break: break-all;
      }
    }
  }
  .user-account-info {
    .tab-content-title {
      margin-bottom: 10px;
      font-size: 1rem;
      font-weight: 500;
      color: var(--primary-font-color);
    }
    .mobile-history-list {
      display: none; //history list display none for desktop
    }
    .tab-content {
      padding: 10px 25px;
      .user-plan-card {
        margin-bottom: 25px;
        border: 1px solid var(--border-color);
        padding: 10px 20px;
        border-radius: var(--border-radius);

        .plan-name {
          display: inline-block;
          font-size: 0.9rem;
          font-weight: 600;
          color: var(--primary-font-color);
        }
      }
      .outline-action-btn {
        border-radius: 4px;
        border-color: var(--secondary-font-color);
        color: var(--secondary-font-color);
      }
      .ant-table table {
        border-collapse: collapse;
      }
      .ant-table-thead tr > th {
        @extend .label-dark;
        border-bottom: 1px solid var(--border-color) !important;
        padding: 15px 10px;
      }
      .ant-table-body {
        overflow-y: auto !important;
      }

      .ant-table-tbody > tr > td {
        color: var(--primary-font-color);
        padding: 15px 10px;
      }

      .admin-controler-wrapper,
      .documents-wrapper,
      .payment-history-wrapper,
      .conversion-history-wrapper {
        padding-bottom: 30px;
        .documents-table,
        .payment-history-table,
        .conversion-history-table {
          border: 1px solid var(--border-color);
          border-radius: var(--border-radius);
          overflow: hidden;
          .ant-table-tbody > tr.ant-table-row-selected > td {
            background-color: var(--white);
          }
          .ant-table-selection-col {
            width: 40px;
          }
          .ant-table-selection-column {
            background-color: var(--white);
          }
          .ant-table-thead {
            .table-cell-light {
              background-color: var(--white);
              color: var(--primary-font-color);
              &::before {
                display: none;
              }
            }
          }
        }
      }
      .table-actions-btns {
        .ant-btn[disabled] {
          span {
            color: #cecaca !important;
          }
          border-color: #cecaca;
        }
      }
      .conversion-history-table {
        .ant-table-pagination.ant-pagination {
          margin: 15px 10px;
        }
      }
      .status-tag {
        border-radius: 8px;
        padding: 2px 15px;
        border: none;
        font-weight: 500;
        font-size: 12px;
      }

      .active-status {
        color: green;
        background-color: #72bb8841;
      }
      .deactive-status {
        color: rgb(201, 39, 39);
        background-color: rgba(228, 101, 101, 0.363);
      }

      .documents-wrapper {
        margin-top: 20px;
      }
      .document-action-btn {
        color: var(--secondary-font-color);
        font-size: 0.9rem;
        font-weight: 500;
      }

      .conversion-history-wrapper {
        .table-header-row {
          margin-top: -20px;
          margin-bottom: 15px;
        }
        .ant-row {
          width: 100%;
          .ant-col:nth-child(2) {
            div {
              float: right;
            }
          }
        }
        padding-top: 1rem;
      }

      .process-again-btn {
        height: 30px;
        color: var(--secondary-font-color);
      }

      .admin-controler-wrapper {
        .ant-row {
          margin-bottom: 5px;
        }
        .ant-col:nth-child(2) {
          div {
            float: right;
            margin-bottom: 10px;
          }
        }
      }

      .api-key-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding-bottom: 20px;

        .api-key-box {
          margin-top: 20px;
        }
        .api-key-label {
          font-size: 1.4rem;
          font-weight: 500;
          color: var(--primary-font-color);
        }
        .api-key-input {
          min-width: 550px;
        }

        .generate-api-btn {
          width: 170px;
          height: 40px;
          font-size: 1.2rem;
          border-radius: 4px;
          margin-top: 15px;
          background-color: transparent;
          color: var(--secondary-color);
          &:hover {
            background-color: var(--secondary-color);
            color: var(--white);
          }
        }
      }
    }
  }

  .ant-tabs-nav {
    padding: 15px 25px;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    display: flex;
    justify-content: center;
    color: var(--primary-font-color);
    opacity: 0.5;
    font-size: 0.8rem;
    font-weight: 600;
    &:hover {
      color: var(--lt-blue);
    }
  }
  .ant-tabs-tab-active {
    padding: 10px 5px;
    border-radius: 14px;
    background-color: var(--lt-sky-02);
    opacity: 1;
    color: var(--lt-blue);
  }
}
.account-edit-modal {
  padding: 30px 40px 40px 40px;
  .title {
    font-size: 1.2rem !important;
    text-align: center !important;
  }
  .account-edit-title {
    font-size: 1rem;
    margin: 25px 0 15px 0;
    font-weight: 500;
    color: var(--primary-font-color);
  }
  .user-avatar-border .ant-avatar-circle {
    border: 2px solid var(--lt-blue) !important;
  }
  .admin-avatar-border .ant-avatar-circle {
    border: 2px solid green !important;
  }

  .user-avatar {
    cursor: pointer;
    position: relative;
    .camera-icon {
      background-color: var(--lt-blue);
      width: 40px;
      height: 40px;
      position: absolute;
      left: 50%;
      bottom: -15px;
      transform: translate(-50%, 0);
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: var(--white);
      font-size: 1.2rem;
    }
  }
  .update-btn {
    height: 45px;
    margin-top: 10px;
  }
}

.upgrade-subcription-plan {
  .subcription-title {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--primary-font-color);
    margin: 10px 0 15px 0;
  }
  overflow-y: auto;
  padding: 30px;

  .section-title {
    font-size: 2rem;
    font-weight: 600;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      height: 8px;
      width: 60px;
      bottom: -10px;
      border-radius: 4px;
      background-color: #f5854a;
    }
  }
  .pricing-cards {
    .active-plan {
      padding: 30px 40px 30px 10px !important;
      .label {
        font-size: 0.8rem;
        font-weight: 600;
      }
      .active-badge {
        position: absolute;
        width: 80px;
        height: 25px;
        text-align: center;
        line-height: 25px;
        border-radius: 20px;
        right: 0;
        top: 0;
      }
      .active-badge-icon {
        @extend .active-badge;
        right: 30px;
        top: 2px;
      }
      .card-header {
        position: relative;
        .plan-name,
        .pricing {
          color: var(--primary-font-color);
        }
      }
      .card-body {
        margin-top: 15px;
        .feature-tick-icon {
          margin-right: 10px;
          color: var(--primary-font-color);
          opacity: 0.7;
        }
        ul {
          padding-left: 10px;
          list-style: none;
        }
        ul > li {
          color: var(--primary-font-color);
        }
      }
    }
    .startup {
      background-color: var(--white) !important;
      border: 1px solid var(--border-color);
      .card-header {
        position: relative;
        .plan-name,
        .pricing {
          color: var(--primary-font-color);
        }
      }
      .card-body {
        margin-top: 15px;
        .feature-tick-icon {
          margin-right: 10px;
          color: var(--primary-font-color);
          opacity: 0.7;
        }
        ul {
          padding-left: 10px;
          list-style: none;
        }
        ul > li {
          color: var(--primary-font-color);
        }
      }
      .card-footer {
        .choose-plan-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--primary-bg-color);
        }
      }
    }
    .basic,
    .enterprise {
      box-shadow: 0px 10px 20px #0000001a;
      background-color: #fff;
      .card-header {
        .plan-name,
        .pricing {
          color: var(--primary-font-color);
        }
      }
      .card-body {
        .feature-tick-icon {
          margin-right: 10px;
          color: #000000;
          opacity: 0.3;
        }
        ul {
          padding-left: 10px;
        }
        ul > li {
          list-style: none;
          color: var(--primary-font-color);
        }
      }
      .card-footer {
        .choose-plan-btn {
          background-color: #f5854a;
        }
      }
    }
    .pricing-card {
      width: 100%;
      padding: 30px 50px;
      border-radius: var(--border-radius);
      background-color: var(--lt-sky-03);
      .card-header {
        .plan-name {
          font-weight: 500;
          line-height: 1;
          font-size: 1.2rem;
        }
        .pricing {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
      .card-body {
        ul > li {
          padding: 8px 0;
        }
      }
      .card-footer {
        display: grid;
        place-items: center;
        .choose-plan-btn {
          width: 280px;
          margin-top: 15px;
          height: 40px;
          border-radius: var(--border-radius);
          color: #fff;
          border: none;
          font-size: 0.8rem;
          box-shadow: 0px 10px 20px #0000001a;
          &:hover,
          &:focus,
          &:active {
            border-color: #f5854a;
          }
        }
      }
    }
  }
}

.user-role-tag {
  min-width: 50px;
  text-transform: capitalize;
  margin-right: 0;
}

.select-role .ant-select-selector {
  border-radius: var(--border-radius) !important;
  height: 40px !important;
  border: 1px solid #24253734;
  &:focus,
  &:active {
    border-color: #24253734;
  }
  .ant-select-selection-item {
    color: var(--primary-font-color);
    font-size: 0.8rem;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.plan-logo {
  color: var(--white);
  font-size: 1.2rem;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--lt-green);
}

.label {
  font-size: 0.9rem;
  color: var(--primary-font-color);
  opacity: 0.4;
}
.label-dark {
  @extend .label;
  font-size: 0.9rem;
  opacity: 0.5;
  font-weight: 500;
}
.user-setting,
.plan-setting {
  cursor: pointer;
  color: var(--secondary-color);
  font-weight: 500;
}

@media only screen and (max-width: 1225px) {
  .dashboard-wrapper {
    padding: 50px 20px 20px 20px;
  }
}
@media only screen and (max-width: 996px) {
  .page-title {
    text-align: center;
  }
  .user-account-wrapper {
    .ant-row,
    .ant-col {
      width: 100%;
    }
    .user-personal-info {
      min-height: 450px;
      height: 450px;
    }
    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center;
    }
    .user-account-info {
      .tab-content {
        padding: 10px 15px;
      }
      .desktop-history-list {
        display: none;
      }
      .mobile-history-list {
        display: block;
      }
    }

    .ant-tabs-nav {
      padding: 15px 0;
      .tab-content {
        padding: 10px 15px;
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 15px;
    }
  }
}
@media only screen and (max-width: 426px) {
  .account-edit-modal {
    padding: 30px 20px 40px 20px;
  }
  .user-account-wrapper {
    .user-account-info {
      .ant-tabs-nav-list {
        flex-wrap: wrap;
        padding: 0 10px;
      }
      .tab-content {
        .api-key-wrapper {
          .api-key-input {
            min-width: 280px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 652px) {
  .user-account-wrapper {
    .user-account-info {
      .tab-content {
        .api-key-wrapper {
          .api-key-input {
            min-width: 400px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 376px) {
  .page-title {
    text-align: center;
  }

  .user-account-wrapper {
    .ant-row,
    .ant-col {
      width: 100%;
    }
    .user-personal-info {
      min-height: 450px;
      height: 450px;
    }
    .ant-tabs-nav-wrap {
      display: flex;
      justify-content: center;
    }

    .ant-tabs-nav {
      padding: 15px 0;
      .tab-content {
        padding: 10px 15px;
      }
    }
    .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 15px;
    }
  }
}
