.landing-page-wrapper {
  background-color: var(--secondary-bg-color);
  scroll-behavior: smooth;
  overflow-x: hidden;

  .section-title {
    position: relative;
    text-align: center;
    font-size: 2.2rem;
    color: var(--primary-font-color);
    font-weight: 600;
    margin-bottom: 40px;
    &::before {
      content: "";
      position: absolute;
      height: 7px;
      width: 60px;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -10px;
      border-radius: 4px;
      background-color: #f5854a;
    }
  }
  .section-desc {
    text-align: center;
  }

  // not in use
  .step-work-section {
    background-color: var(--secondary-bg-color);
    padding: 3rem 0 3rem 0;
    .section-title {
      text-align: center;
      font-size: 2.5rem;
      color: var(--primary-font-color);
      font-weight: 500;
      margin-bottom: 10px;
    }
    .section-desc {
      width: 450px;
      text-align: center;
      margin: 0 auto;
      color: var(--primary-font-color);
      font-size: 1rem;
    }
    .steps-wrapper {
      position: relative;
      max-width: 1200px;
      margin: 3rem auto 0 auto;
      z-index: 1;
      .line-bottom {
        top: 25px;
        left: 250px;
        position: absolute;
        width: 350px;
        height: 150px;
        background: transparent;
        border: none;
        border-bottom: dashed 3px var(--primary-font-color);
        opacity: 0.2;
        padding: 60px 50px;
        border-radius: 0 0 50% 50%;
        transform: rotate(10deg);
        z-index: -1;
      }
      .line-top {
        top: 170px;
        right: 260px;
        width: 320px;
        height: 60px;
        z-index: -1;
        position: absolute;
        background: transparent;
        border: none;
        border-top: dashed 3px var(--primary-font-color);
        padding: 40px 40px;
        border-radius: 50%;
        opacity: 0.2;
        transform: rotate(10deg);
      }
      .step-center {
        display: grid;
        place-items: center;
      }
      .step-container {
        display: inline-block;
      }
      .step-icon {
        padding: 15px 10px;
        width: 75px;
        & > img {
          width: 100%;
        }
      }
      .step-box {
        min-width: 160px;
        padding: 8px 20px;
        display: inline-block;
        box-shadow: 0px 10px 20px #0000001a;
        border-radius: var(--border-radius);
        opacity: 1;

        .step-no {
          font-size: 13px;
          letter-spacing: 0px;
          color: var(--primary-font-color);
          opacity: 0.76;
        }
        .step-label {
          font: 13px;
          letter-spacing: 0px;
          color: var(--primary-font-color);
          opacity: 0.76;
          font-weight: bold;
        }
      }

      .step-1 {
        background: #c9e4ff 0% 0% no-repeat padding-box;
      }
      .step-2 {
        background: #ffd2ba 0% 0% no-repeat padding-box;
      }
      .step-3 {
        background: #a1fccb 0% 0% no-repeat padding-box;
      }
    }
  }
}

.custom-btn {
  border: none;
  float: right;
  background: var(--dk-orange);
  box-shadow: 0px 10px 20px var(--lt-shadow-color);
  border-radius: var(--border-radius);
  opacity: 1;
  color: var(--white);
  height: 40px;
  width: 100%;
  &:hover,
  &:active,
  &:focus {
    box-shadow: 0px 10px 20px var(--lt-shadow-color);
    background: var(--dk-orange);
  }
}

#user {
  margin-top: 20px;
}

#contact-us-form_name,
#contact-us-form_email,
#user-edit-form_totalCredit,
#user-edit-form_role,
#user-edit-form_leftCredit,
#user_name,
#login-form_email,
#forgot-ps-form_email,
#login-form_username,
#account-edit-form_name,
#account-edit-form_email,
#account-edit-form_phone,
#personal-detail-form_email,
#personal-detail-form_name,
#personal-detail-form_noOfPages,
#payment-detail-form_card-no,
#payment-detail-form_card-holder-name,
#payment-detail-form_ex-date,
#payment-detail-form_card-cvv,
.ant-input-password,
#user_email,
#user_contact {
  border-radius: var(--border-radius);
  height: 40px;
}
#contact-us-form_requirement_desc,
#contact-us-form_queries {
  border-radius: var(--border-radius);
}

.anticon-close {
  padding: 4px;
  background-color: var(--secondary-color);
  border-radius: 50%;
  color: #fff;
  font-size: 0.8rem;
}

.div-center {
  padding: 20px;
}

@media only screen and (max-width: 450px) {
  .header_container {
    justify-content: center !important;
    padding: 10px 15px;
    .app-header-desc {
      font-size: 0.7rem;
    }
  }
}

@keyframes MoveUpDown {
  0%,
  100% {
    transform: translate(0, 30px);
  }
  50% {
    transform: translate(0, -20px);
  }
}
