.main-layout {
  background-color: var(--secondary-bg-color);
  .content-wrapper {
    padding-top: 60px;
  }
  .backToTop {
    right: 30;
    bottom: 20;
  }

  .user-avatar-border .ant-avatar-circle {
    border: 2px solid var(--lt-blue) !important;
  }
  .admin-avatar-border .ant-avatar-circle {
    border: 2px solid green !important;
  }
}

@media only screen and (max-width: 426px) {
  .backToTop {
    right: 30;
    bottom: 50 !important;
  }
}
