.privacy-policy-wrapper {
  .header {
    min-height: 400px;
    background-color: var(--mask-bg-color);
    width: 100%;
    .title {
      font-size: 4rem;
      font-weight: 600;
      position: relative;
      text-align: center;
      padding-top: 3rem;
      &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        height: 8px;
        width: 60px;
        bottom: -10px;
        border-radius: 4px;
        background-color: #f5854a;
      }
    }
    .desc {
      color: var(--primary-font-color);
      font-size: 2rem;
      text-align: center;
    }
  }

  .privacy-policy-container {
    max-width: 1000px;
    min-height: 400px;
    background-color: #fff;
    box-shadow: var(--box-shadow);
    margin: -7rem auto 5rem auto;
    border-radius: var(--border-radius);
    padding: 50px 80px;
    ul li,
    ol li,
    h2,
    p {
      color: var(--primary-font-color);
      line-height: 1.5;
    }
    ul li,
    ol li {
      font-size: 1.1rem;
      margin-bottom: 15px;
    }
    h2 {
      font-size: 1.5rem;
      font-weight: bold;

      &:not(:first-child) {
        margin-top: 3rem;
      }
    }
    p {
      font-size: 1.1rem;
      line-height: 1.6;
    }
    h3 {
      font-size: 1.1rem;
      font-weight: bold;
      margin-top: 1.5rem;
    }
  }
}

@media only screen and (max-width: 426px) {
  .privacy-policy-wrapper {
    .header {
      min-height: 350px;
      .title {
        font-size: 3rem;
      }
      .desc {
        font-size: 1.6rem;
      }
    }
    .use-demo-container {
      .ant-steps-item {
        min-height: 40vh;
      }
      .ant-steps-item-description {
        padding-bottom: 40px;
      }
    }

    .privacy-policy-container {
      padding: 50px 30px;
      margin: -7rem 20px 5rem 20px;

      ul li {
        font-size: 1rem;
      }
      h2 {
        font-size: 1.2rem;
        font-weight: 500;

        &:not(:first-child) {
          margin-top: 3rem;
        }
      }
      p {
        font-size: 1rem;
        word-wrap: break-word;
      }
      h3 {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 376px) {
  .privacy-policy-wrapper {
    .header {
      .title {
        font-size: 2.8rem;
      }
      .desc {
        font-size: 1.6rem;
        margin-top: 30px;
      }
    }

    .privacy-policy-container {
      padding: 50px 20px;
      margin: -7rem 20px 4rem 20px;
    }
  }
}
@media only screen and (max-width: 331px) {
  .privacy-policy-wrapper {
    .privacy-policy-container {
      margin: -7rem 10px 4rem 10px;
    }
    .privacy-policy-container {
      padding: 50px 15px;
    }
  }
}
