.extract-prv-section {
  background-color: var(--secondary-bg-color);
  padding: 13rem 0 3rem 0;
  .section-title {
    position: relative;
    text-align: center;
    font-size: 2.2rem;
    color: var(--primary-font-color);
    font-weight: 600;
    margin-bottom: 25px;
    &::before {
      content: "";
      position: absolute;
      height: 7px;
      width: 60px;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: -10px;
      border-radius: 4px;
      background-color: #f5854a;
    }
  }
  .extract-prv-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .ant-tabs-top {
      width: 100%;
    }

    .ant-tabs-nav {
      max-width: 900px;
      min-width: 400px;
      padding: 10px 25px;
      margin: 0 auto 20px auto;
      overflow: hidden;
      box-shadow: 0px 10px 20px #0000001a;
      border-radius: var(--border-radius);
      background-color: var(--white);
      display: none; //tabs button not show
      &::before {
        border-bottom: none;
      }
    }
    .ant-tabs-ink-bar {
      display: none;
    }
    .ant-tabs-tab {
      min-width: 200px;
      padding: 8px 15px !important;
      color: var(--primary-font-color);
      opacity: 0.5;

      font-size: 0.8rem;
      height: 35px;
      font-weight: 600;
      &:hover {
        opacity: 1;
        color: var(--lt-blue);
      }
    }
    .ant-tabs-tab-active {
      border-radius: 14px;
      background-color: var(--lt-sky-02);
      opacity: 1;
      color: var(--lt-blue);
      text-align: center;
    }
    .ant-tabs-content {
      // max-width: 1400px;
      margin: 0 auto;
      p {
        font-weight: 600;
        font-size: 1rem;
        color: var(--primary-font-color);
        text-align: center;
        max-width: 700px;
        margin: 15px auto 20px auto;
      }
      .process-gallery-row {
        padding: 2rem 0;
        min-height: 430px;
        .label-dark {
          margin-top: 5px;
          margin-left: 8px;
        }
        .arrow-line-up-img,
        .arrow-line-down-img {
          margin-left: 50px;
        }
        .arrow-line-up-img {
          margin-top: -20px;
        }
        .arrow-line-down-img {
          margin-bottom: -20px;
        }
        .ant-col:nth-child(even) {
          align-self: flex-end;
        }
        .label-dark {
          font-weight: 600;
        }
        .process-img-box {
          background-color: var(--white);
          box-shadow: var(--box-shadow);
          border-radius: var(--border-radius);
          min-height: 500px;
          max-height: 900px;
          width: 250px;
          overflow: hidden;
          img {
            object-fit: fill;
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
.marketing-section-one {
  .section-desc-md {
    max-width: 500px;
  }
  .text-extract-img {
    .hero-carousel {
      width: 450px;
      overflow: hidden;
      border-radius: var(--border-radius);
      box-shadow: var(--box-shadow);
    }
    .banner-img {
      width: 100%;
      height: 300px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}
.marketing-section-two,
.marketing-section-one {
  .ant-row {
    max-width: 1200px;
    margin: 0 auto;
  }
  padding: 5rem 0;

  .section-title-md {
    position: relative;
    font-size: 2.8rem;
    font-weight: 600;
    color: var(--primary-font-color);
    line-height: 1.4;
    &::before {
      content: "";
      position: absolute;
      height: 7px;
      width: 60px;
      bottom: -12px;
      border-radius: 4px;
      background-color: #f5854a;
    }
  }
  .section-desc-md {
    margin-top: 40px;
    // max-width: 400px;
    font-weight: 500;
    font-size: 1.5rem;
  }
}
.marketing-section-two {
  padding-bottom: 4rem;

  .company-logo {
    img {
      width: 200px;
    }
  }
}

@media only screen and (max-width: 426px) {
  .extract-prv-section {
    padding: 9rem 15px 2rem 15px !important;
    .section-title {
      margin-bottom: 20px;
    }
    .ant-tabs-content {
      // max-width: 1400px;
      margin: 0 auto;
      p {
        margin: 5px auto 20px auto;
      }
    }
    .extract-prv-wrapper {
      .ant-tabs-content {
        .process-gallery-row {
          padding: 2rem 0 0 0;
          height: auto;
          margin: 0 0 !important;
          .arrow-line-up-img,
          .arrow-line-down-img {
            display: none;
          }
          .label-dark {
            text-align: center;
            margin-bottom: 25px;
          }
          .process-img-box {
            height: 230px !important;
            width: 260px !important;
          }
          .process-img-box:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .marketing-section-two,
  .marketing-section-one {
    padding: 20px 15px;
    .sec-row {
      max-width: 900px;
      margin: 0 auto;
    }
    .banner-img {
      width: 180px;
    }

    .section-title-md {
      display: grid;
      place-items: center;
      text-align: center;
      font-size: 2rem;
      &::before {
        content: "";
        position: absolute;
        height: 7px;
        width: 60px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 4px;
        background-color: #f5854a;
      }
    }
    .section-desc-md {
      display: grid;
      place-items: center;
      text-align: center;
    }
    .text-extract-img {
      display: grid;
      place-items: center;
      margin: 0 auto;
    }
  }
  .marketing-section-one {
    .text-extract-img {
      .hero-carousel {
        width: 350px !important;
      }
      .banner-img {
        width: 350px !important;
        height: 270px !important;
        img {
          height: 100%;
        }
      }
    }
  }

  .marketing-section-two {
    padding-bottom: 2rem;
    .sec-row {
      flex-wrap: wrap-reverse;
      align-items: center !important;
      justify-content: center;
    }
    .company-logo {
      padding-left: 20px;
      padding-top: 10px;
      img {
        width: 140px !important;
      }
    }
  }
  [data-aos][data-aos][data-aos-delay="500"].aos-animate {
    transition-delay: 0.4s !important;
  }
  [data-aos][data-aos][data-aos-delay="700"].aos-animate {
    transition-delay: 0.5s !important;
  }
  [data-aos][data-aos][data-aos-delay="900"].aos-animate {
    transition-delay: 0.6s !important;
  }
  [data-aos][data-aos][data-aos-delay="1100"].aos-animate {
    transition-delay: 0.6s !important;
  }
  [data-aos][data-aos][data-aos-delay="1300"].aos-animate {
    transition-delay: 0.7s !important;
  }
}

@media only screen and (max-width: 769px) {
  .extract-prv-section {
    padding: 9rem 15px 2rem 15px;
    .section-title {
      margin-bottom: 20px;
    }
    .ant-tabs-content {
      // max-width: 1400px;
      margin: 0 auto;
      p {
        margin: 5px auto 20px auto;
      }
    }
    .extract-prv-wrapper {
      .ant-tabs-content {
        .process-gallery-row {
          padding: 2rem 0 0 0;
          height: auto;
          margin: 0 0 !important;
          .arrow-line-up-img,
          .arrow-line-down-img {
            display: none;
          }
          .label-dark {
            text-align: center;
            margin-bottom: 25px;
          }
          .process-img-box:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
  .marketing-section-two,
  .marketing-section-one {
    padding: 20px 15px;
    .ant-row {
      max-width: 900px;
      margin: 0 auto;
      padding-left: 0px !important;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .ant-col {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
      }
      // .ant-space-vertical {
      //   flex-direction: row;
      // }
    }
    .banner-img {
      width: 250px;
    }
    .section-title-md {
      display: grid;
      place-items: center;
      text-align: center;
      font-size: 2rem;
      &::before {
        content: "";
        position: absolute;
        height: 7px;
        width: 60px;
        left: 50%;
        transform: translate(-50%, 0);
        border-radius: 4px;
        background-color: #f5854a;
      }
    }

    .section-desc-md {
      display: grid;
      place-items: center;
      text-align: center;
      margin: 40px auto;
    }
    .text-extract-img {
      display: grid;
      place-items: center;
      margin: 0 auto;
    }
  }

  .marketing-section-two {
    padding-bottom: 2rem;
    .ant-row .ant-col {
      flex-wrap: wrap-reverse;
      align-items: center !important;
      justify-content: center;
      &:first-child {
        order: 2;
      }
      &:nth-child(1) {
        display: flex;
        justify-content: center;
      }
    }
    .section-title-md {
      display: grid;
      place-items: center;
      text-align: center;
      font-size: 2rem;
      max-width: 500px;
      margin: 0 auto;
    }
    .section-desc-md {
      display: grid;
      place-items: center;
      text-align: center;
      margin: 40px auto;
      max-width: 600px;
    }
    .company-logo {
      padding-left: 20px;
      padding-top: 10px;
      img {
        width: 140px;
      }
    }
  }
}

@media only screen and (max-width: 1225px) {
  .extract-prv-section {
    padding: 2rem 15px 2rem 15px;
  }
  .landing-page-wrapper {
    .section-title {
      margin-bottom: 20px;
    }
  }
  .marketing-section-two,
  .marketing-section-one {
    .ant-row {
      max-width: 1000px;
      margin: 0 auto;
    }
  }
  .marketing-section-one {
    .text-extract-img {
      .hero-carousel {
        width: 350px;
      }
      .banner-img {
        width: 350px;
        height: 100%;
      }
    }
    .section-title-md {
      font-size: 2.2rem;
    }
    .section-desc-md {
      font-size: 1.2rem;
    }
  }

  .marketing-section-two {
    .company-logo {
      img {
        width: 180px;
      }
    }
    .section-title-md {
      font-size: 2.2rem;
    }
    .section-desc-md {
      font-size: 1.2rem;
    }
  }
}
@media only screen and (max-width: 1025px) {
  .extract-prv-section {
    padding: 2rem 15px 2rem 15px;
    .extract-prv-wrapper {
      .ant-tabs-content {
        // max-width: 1400px;
        margin: 0 auto;
        p {
          font-weight: 500;
          font-size: 1rem;
          color: var(--primary-font-color);
          text-align: center;
          max-width: 700px;
          margin: 15px auto 20px auto;
        }
        .process-gallery-row {
          .ant-col {
            padding-left: 10px !important;
            padding-right: 10px !important;
          }

          .process-img-box {
            height: 200px;
            width: 200px;
          }
        }
      }
    }
  }
  .landing-page-wrapper {
    .section-title {
      margin-bottom: 20px;
    }
  }
  .marketing-section-two,
  .marketing-section-one {
    .ant-row {
      max-width: 1000px;
      margin: 0 auto;
      padding-left: 60px;
    }
  }
  .marketing-section-one {
    .text-extract-img {
      .hero-carousel {
        width: 350px;
      }
      .banner-img img {
        width: 100%;
        height: 270px;
      }
    }
    .section-title-md {
      font-size: 2.2rem;
    }
    .section-desc-md {
      font-size: 1.2rem;
    }
  }

  .marketing-section-two {
    .company-logo {
      img {
        width: 180px;
      }
    }
    .section-title-md {
      font-size: 2.2rem;
    }
    .section-desc-md {
      font-size: 1.2rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .marketing-section-one {
    .text-extract-img {
      .hero-carousel {
        width: 500px;
      }
      .banner-img {
        width: 500px;
        height: 350px;
        img {
          height: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  .extract-prv-section {
    padding: 11rem 15px 2rem 15px;
  }
  .landing-page-wrapper {
    .section-title {
      margin-bottom: 20px;
    }
  }
  .marketing-section-one {
    .text-extract-img {
      .hero-carousel {
        width: 270px !important;
      }
      .banner-img {
        width: 270px !important;
        height: 240px !important;
        img {
          height: 100%;
        }
      }
    }
    .section-title-md {
      font-size: 1.9rem;
    }
    .section-desc-md {
      font-size: 1.2rem;
    }
  }

  .marketing-section-two {
    .company-logo {
      img {
        width: 120px;
      }
    }
    .section-title-md {
      font-size: 1.9rem;
    }
    .section-desc-md {
      font-size: 1.2rem;
    }
  }
}
@media only screen and (max-width: 320px) {
  .extract-prv-section {
    padding: 12rem 15px 2rem 15px !important;
  }
}
