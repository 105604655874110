@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&family=Roboto&display=swap");
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* css varrialbe */

:root {
  --white: #ffffff;
  --black: #000;
  --primary-font-color: #242538;
  --primary-font-color-lt: #24253894;
  --secondary-font-color: #376dc6;
  --primary-bg-color: #242538;
  --secondary-color: #39a2ff;
  --secondary-bg-color: #f8fcff;
  --mask-bg-color: #d0eaff;

  --dk-border-color: #24253780;
  --dk-gray-01: #0000000d;
  --lt-shadow-color: #0000001a;
  --dk-gray-03: #242537;
  --dk-gray-04: #313740;
  --lt-gray-label: #707070;
  --dk-gray-06: #fafafa;

  --dk-orange: #f5854a;
  --dk-brown: #7c3713;

  --lt-gray-bg: #f2f2f2;
  --dk-sky-color: #5193ed;
  --lt-sky-blue: #d0eaff;
  --lt-blue: #376dc6;
  --lt-blue-border-color: #56a1f8;
  --lt-gray: #f0f0f0;
  --lt-sky-01: #f8fcff;
  --lt-sky-02: #cce7ff;
  --lt-sky-03: #c9e4ff;
  --lt-sky-04: #a1fccb;
  --border-color: rgba(0, 0, 0, 0.06);

  --lt-pink: #ffd2ba;

  --unnamed-color-376dc6: #376dc6;
  --lt-green-00: #72bb88;
  --lt-green: #57be83;
  --border-radius: 8px;

  --box-shadow: 0px 10px 20px #0000001a;
}
